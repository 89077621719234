var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.roles, function(role, index) {
        return _c(
          "gov-form-group",
          { key: role.index, attrs: { invalid: _vm.errors.has("roles") } },
          [
            _c(
              "gov-inset-text",
              [
                _vm.errors.has("roles." + index)
                  ? _c("gov-error-message", {
                      attrs: { for: "roles." + index },
                      domProps: {
                        textContent: _vm._s(_vm.errors.get("roles." + index))
                      }
                    })
                  : _vm._e(),
                _c("ck-select-input", {
                  attrs: {
                    value: _vm.roles[index].role,
                    id: "roles_" + index + "_role",
                    label: "User type",
                    options: _vm.userRoleOptions,
                    error: _vm.errors.get("roles." + index + ".role")
                  },
                  on: {
                    input: function($event) {
                      return _vm.onRoleInput({ key: index, value: $event })
                    }
                  }
                }),
                _vm.showOrganisationSelect(index)
                  ? [
                      _vm.loadingOrganisations
                        ? _c("gov-form-group", [_c("ck-loader")], 1)
                        : _c("ck-select-input", {
                            attrs: {
                              value: _vm.roles[index].organisation_id,
                              id: "roles_" + index + "_organisation_id",
                              label: "Organisation",
                              options: _vm.organisations,
                              error: _vm.errors.get(
                                "roles." + index + ".organisation_id"
                              )
                            },
                            on: {
                              input: function($event) {
                                return _vm.onOrganisationInput({
                                  key: index,
                                  value: $event
                                })
                              }
                            }
                          }),
                      _vm.showServiceSelect(index)
                        ? [
                            !_vm.services.hasOwnProperty(
                              _vm.roles[index].organisation_id
                            )
                              ? _c("gov-form-group", [
                                  _vm._v(
                                    "\n            Select an organisation\n          "
                                  )
                                ])
                              : _vm.services[_vm.roles[index].organisation_id]
                                  .loading
                              ? _c("gov-form-group", [_c("ck-loader")], 1)
                              : _c("ck-select-input", {
                                  attrs: {
                                    value: _vm.roles[index].service_id,
                                    id: "roles_" + index + "_service_id",
                                    label: "Service",
                                    options:
                                      _vm.services[
                                        _vm.roles[index].organisation_id
                                      ].items,
                                    error: _vm.errors.get(
                                      "roles." + index + ".service_id"
                                    )
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.onServiceInput({
                                        key: index,
                                        value: $event
                                      })
                                    }
                                  }
                                })
                          ]
                        : _vm._e()
                    ]
                  : _vm._e(),
                _c("gov-section-break", { attrs: { size: "m" } }),
                _c(
                  "gov-button",
                  {
                    attrs: { error: "" },
                    on: {
                      click: function($event) {
                        return _vm.onRemovePermission(index)
                      }
                    }
                  },
                  [_vm._v("Remove")]
                )
              ],
              2
            )
          ],
          1
        )
      }),
      _vm.roles.length === 0
        ? _c("gov-inset-text", [_c("gov-body", [_vm._v("No permissions")])], 1)
        : _vm._e(),
      _c("gov-button", { on: { click: _vm.onAddPermission } }, [
        _vm._v("Add permission")
      ]),
      _vm.errors.has("roles")
        ? _c("gov-error-message", {
            attrs: { for: "roles" },
            domProps: { textContent: _vm._s(_vm.errors.get("roles")) }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }