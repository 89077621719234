var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ck-text-input", {
    attrs: {
      value: _vm.value,
      id: _vm.id,
      label: _vm.label,
      type: _vm.type,
      hint:
        "The password must be at least eight characters long, contain one uppercase letter, one lowercase letter, one number and one special character (!#$%&()*+,-./:;<=>?@[]^_`{|}~)",
      error: _vm.error
    },
    on: {
      input: function($event) {
        return _vm.$emit("input", $event)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "after-input",
        fn: function() {
          return [
            _c(
              "GovButton",
              {
                attrs: { type: "button" },
                on: { click: _vm.toggleShowPassword }
              },
              [_vm._v(_vm._s(_vm.toggleShowPasswordText))]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }