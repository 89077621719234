var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ck-text-input", {
        attrs: {
          value: _vm.first_name,
          id: "first_name",
          label: "First name",
          type: "text",
          error: _vm.errors.get("first_name")
        },
        on: {
          input: function($event) {
            return _vm.onInput("first_name", $event)
          }
        }
      }),
      _c("ck-text-input", {
        attrs: {
          value: _vm.last_name,
          id: "last_name",
          label: "Last name",
          type: "text",
          error: _vm.errors.get("last_name")
        },
        on: {
          input: function($event) {
            return _vm.onInput("last_name", $event)
          }
        }
      }),
      _c("ck-text-input", {
        attrs: {
          value: _vm.email,
          id: "email",
          label: "Email",
          type: "email",
          error: _vm.errors.get("email")
        },
        on: {
          input: function($event) {
            return _vm.onInput("email", $event)
          }
        }
      }),
      _c("ck-text-input", {
        attrs: {
          value: _vm.phone,
          id: "phone",
          label: "Mobile Phone",
          type: "tel",
          error: _vm.errors.get("phone")
        },
        on: {
          input: function($event) {
            return _vm.onInput("phone", $event)
          }
        }
      }),
      _c("ck-password", {
        attrs: {
          value: _vm.password,
          id: "password",
          label: "Password",
          error: _vm.errors.get("password")
        },
        on: {
          input: function($event) {
            return _vm.onInput("password", $event)
          }
        }
      }),
      _c("gov-section-break", { attrs: { size: "l" } }),
      _c("gov-heading", { attrs: { size: "m" } }, [_vm._v("Permissions")]),
      _c("gov-list", { attrs: { bullet: "" } }, [
        _c("li", [
          _c("strong", [_vm._v("Content admins:")]),
          _vm._v("\n      Add pages, edit pages and remove pages\n    ")
        ]),
        _c("li", [
          _c("strong", [_vm._v("Organisation admins:")]),
          _vm._v(
            "\n      Add services, add users, edit services, edit users, manage referrals\n    "
          )
        ]),
        _c("li", [
          _c("strong", [_vm._v("Service admins:")]),
          _vm._v("\n      Edit services, manage referrals\n    ")
        ]),
        _c("li", [
          _c("strong", [_vm._v("Service workers:")]),
          _vm._v("\n      Manage referrals\n    ")
        ])
      ]),
      _c("user-roles-input", {
        attrs: { roles: _vm.roles, errors: _vm.errors },
        on: {
          input: function($event) {
            return _vm.$emit("update:roles", $event)
          },
          clear: function($event) {
            return _vm.$emit("clear", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }